<template>
  <el-dialog visible append-to-body :show-close="false" custom-class="task-snooze-modal">
    <template #title>
      <div class="d-flex justify-content-between align-items-start pt-4 pb-5">
        <div class="d-flex flex-column">
          <div class="title-text">{{ $t('operationManagement.taskTable.snoozeModal.title') }}</div>
          <slot name="subtile" />
        </div>
        <Button type="text" class="p-1 text-typography-primary action-btn" @click="$emit('close')">
          <CloseIcon />
        </Button>
      </div>
    </template>
    <div class="task-details__container p-4 d-flex">
      <div class="task-details__icon">
        <BellUserIcon v-if="isTaskOverdue && isTaskSnoozedByUser" class="bell-user-icon" />
        <BellOutlinesIcon v-else-if="isTaskOverdue" class="task-icon" />
        <SnoozeIcon v-else class="task-icon" />
      </div>
      <div>
        <div class="px-3 activated-text">{{ getTaskActivatedText() }}</div>
        <div class="px-3 activated-text">{{ getTaskActivatedTime() }}</div>
      </div>
    </div>
    <div class="fw-bold pt-6">
      {{ $t('operationManagement.taskTable.snoozeModal.taskStallingDuration') }}
    </div>
    <el-radio-group v-model="stallingTime" class="d-flex flex-column mt-2">
      <el-radio :label="STALING_TIME_RADIO_STATE.NO_CHANGE" class="m-0 pt-2">
        <p class="d-inline-flex text-typography-primary">
          {{ $t('operationManagement.taskTable.snoozeModal.stallingTime.noChange') }}
        </p>
      </el-radio>
      <el-radio :label="STALING_TIME_RADIO_STATE.ONE_HOUR" class="m-0 pt-2">
        <p class="d-inline-flex text-typography-primary">
          {{ $t('operationManagement.taskTable.snoozeModal.stallingTime.oneHour') }}
        </p>
      </el-radio>
      <el-radio :label="STALING_TIME_RADIO_STATE.TWO_HOURS" class="m-0 pt-2">
        <p class="d-inline-flex text-typography-primary">
          {{ $t('operationManagement.taskTable.snoozeModal.stallingTime.twoHours') }}
        </p>
      </el-radio>
      <el-radio :label="STALING_TIME_RADIO_STATE.TOMORROW_AT_NINE" class="m-0 pt-2">
        <p class="d-inline-flex text-typography-primary">
          {{ $t('operationManagement.taskTable.snoozeModal.stallingTime.tomorrowAtNine') }}
        </p>
      </el-radio>
      <el-radio :label="STALING_TIME_RADIO_STATE.DAY_AFTER_TOMORROW_AT_NINE" class="m-0 pt-2">
        <p class="d-inline-flex text-typography-primary">
          {{ $t('operationManagement.taskTable.snoozeModal.stallingTime.dayAfterTomorrowAtNine') }}
        </p>
      </el-radio>
      <el-radio :label="STALING_TIME_RADIO_STATE.NEXT_WEEK_AT_NINE" class="m-0 pt-2">
        <p class="d-inline-flex text-typography-primary">
          {{ $t('operationManagement.taskTable.snoozeModal.stallingTime.nextWeekAtNine') }}
        </p>
      </el-radio>
      <el-radio :label="STALING_TIME_RADIO_STATE.PERSONALIZED" class="m-0 pt-2">
        <p class="d-inline-flex text-typography-primary">
          {{ $t('operationManagement.taskTable.snoozeModal.stallingTime.personalized') }}
        </p>
      </el-radio>
    </el-radio-group>
    <div v-if="stallingTime === STALING_TIME_RADIO_STATE.PERSONALIZED">
      <div class="fw-bold mt-3">
        {{ $t('commons.date') }}
      </div>
      <el-date-picker
        v-model="customDate"
        class="mt-1 mx-n2"
        format="dd.MM.yyyy"
        :picker-options="getDatePickerOptions()"
      />
      <div class="fw-bold mt-3">
        {{ $t('commons.hour') }}
      </div>
      <el-select
        v-model="customTime"
        class="mt-1"
        :popper-append-to-body="false"
        :placeholder="$t('commons.searchWithDots')"
      >
        <el-option v-for="time in timeArr" :key="time" :label="time" :value="time" suffix-icon />
      </el-select>
    </div>
    <div class="fw-bold mt-6">
      {{ $t('operationManagement.taskTable.snoozeModal.comments') }}
    </div>
    <el-input v-model="comment" class="comment-area mt-2" type="textarea" :autosize="{ minRows: 4, maxRows: 10 }" />
    <div class="d-flex justify-content-end mt-6">
      <el-button @click="$emit('close')">{{ $t('commons.cancel') }}</el-button>
      <el-button type="primary" :loading="false" @click="handleConfirm"> {{ $t('commons.save') }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { ref } from 'vue';
import { DateTime } from 'luxon';
import { isNil } from 'ramda';
import moment from 'moment';

import { BellOutlinesIcon, SnoozeIcon, BellUserIcon, CloseIcon } from '@/assets/icons';
import { Button } from '@/modules/core';

const STALING_TIME_RADIO_STATE = {
  NO_CHANGE: 'noChange',
  ONE_HOUR: 'oneHour',
  TWO_HOURS: 'twoHours',
  TOMORROW_AT_NINE: 'tomorrowAtNine',
  DAY_AFTER_TOMORROW_AT_NINE: 'dayAfterTomorrowAtNine',
  NEXT_WEEK_AT_NINE: 'nextWeekAtNine',
  PERSONALIZED: 'personalized',
};

const timeArr = [
  '6:00',
  '7:00',
  '8:00',
  '9:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00',
];

export default {
  components: { BellOutlinesIcon, SnoozeIcon, BellUserIcon, CloseIcon, Button },
  props: {
    task: { type: Object, required: true },
  },
  setup(props) {
    return {
      STALING_TIME_RADIO_STATE,
      stallingTime: ref(STALING_TIME_RADIO_STATE.NO_CHANGE),
      comment: ref(props.task.data.comment ?? ''),
      customDate: ref(new Date()),
      customTime: ref(timeArr[3]),
      timeArr,
    };
  },
  computed: {
    isTaskOverdue() {
      return DateTime.fromMillis(this.task.activeAt) <= DateTime.fromJSDate(new Date());
    },
    isTaskSnoozedByUser() {
      return !isNil(this.task.activeBy);
    },
  },
  methods: {
    getDatePickerOptions() {
      return {
        disabledDate: (date) => date < DateTime.local().startOf('day').toJSDate(),
      };
    },
    handleConfirm() {
      const stallingDate = this.getStallingDate();
      this.$emit('update-task', { task: this.task, activeAt: stallingDate, comment: this.comment });
      this.$emit('close');
    },
    getStallingDate() {
      const nowTime = DateTime.fromJSDate(new Date());

      switch (this.stallingTime) {
        case STALING_TIME_RADIO_STATE.NO_CHANGE:
          return null;
        case STALING_TIME_RADIO_STATE.ONE_HOUR:
          return nowTime.plus({ hours: 1 }).toISO();
        case STALING_TIME_RADIO_STATE.TWO_HOURS:
          return nowTime.plus({ hours: 2 }).toISO();
        case STALING_TIME_RADIO_STATE.TOMORROW_AT_NINE:
          return nowTime.plus({ days: 1 }).set({ hour: 9 }).set({ minutes: 0 }).toISO();
        case STALING_TIME_RADIO_STATE.DAY_AFTER_TOMORROW_AT_NINE:
          return nowTime.plus({ days: 2 }).set({ hour: 9 }).set({ minutes: 0 }).toISO();
        case STALING_TIME_RADIO_STATE.NEXT_WEEK_AT_NINE:
          return nowTime.plus({ weeks: 1 }).set({ hour: 9 }).set({ minutes: 0 }).toISO();
        case STALING_TIME_RADIO_STATE.PERSONALIZED:
          return DateTime.fromJSDate(this.customDate)
            .set({ hours: this.customTime.split(':')[0] })
            .set({ minutes: this.customTime.split(':')[1] })
            .toISO();

        default:
          return null;
      }
    },
    getTaskActivatedText() {
      let prefix = '';
      if (isNil(this.task.activeBy)) {
        if (this.isTaskOverdue) {
          prefix = this.$t('operationManagement.taskTable.snoozeModal.taskWasActivated');
        } else {
          prefix = this.$t('operationManagement.taskTable.snoozeModal.taskWillBeActivated');
        }
      } else {
        if (this.isTaskOverdue) {
          prefix = this.$t('operationManagement.taskTable.snoozeModal.taskWasActivatedByUser', {
            name: `${this.task.activeBy.firstName} ${this.task.activeBy.lastName}`,
          });
        } else {
          prefix = this.$t('operationManagement.taskTable.snoozeModal.taskWillBeActivatedByUser', {
            name: `${this.task.activeBy.firstName} ${this.task.activeBy.lastName}`,
          });
        }
      }

      return prefix;
    },
    getTaskActivatedTime() {
      return `${moment(this.task.activeAt).from(new Date())}, ${new Date(this.task.activeAt).toLocaleDateString(
        this.$i18n.locale,
        {
          month: 'short',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        }
      )}`;
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

.action-btn {
  height: fit-content;
  &:hover {
    background: $secondary;
  }
}

:deep(.task-snooze-modal) {
  width: 456px;
  padding: 0px;
  border-radius: 8px;

  .el-dialog {
    &__body {
      padding: 0px 16px 16px;
      margin: 0px;
    }

    &__header {
      padding: 0px 16px;
    }
  }

  input.el-input__inner {
    width: 212px;
  }

  textarea {
    resize: none;
  }
}

.title-text {
  font-size: 24px;
  color: #000000;
  font-weight: $font-weight-bold;
  padding: 0px;
}

.subtitle {
  font-size: 16px;
}

.task-details {
  &__container {
    background: #fbfbfb;
    border: 1px solid #d9dcde;
    border-radius: 4px;
  }
  &__icon {
    width: 24px;
    height: 24px;
  }
}

.bell-user-icon {
  width: 36px !important;
  height: 36px !important;
  margin-top: -4px;
  margin-right: -4px;
}

.activated-text {
  word-break: keep-all;
}

.task-icon {
  width: 100%;
  height: 100%;
}
</style>
