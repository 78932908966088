<template>
  <div>
    <el-popover v-capture-scroll="closePopover" :value="visible" placement="bottom" trigger="manual">
      <div class="d-flex flex-column main-container">
        <div class="container">
          <div class="top-element">
            <div
              class="d-flex headline-text"
              :class="{ ['position-relative']: headlineHover && headlineEllipsis }"
              @mouseleave="headlineHover = false"
              @mouseenter="headlineHover = true"
            >
              <h4
                ref="headlineText"
                class="w-100 text-truncate d-inline-block text-nowrap px-1 mx-n1 text-typography-primary"
              >
                {{ $t('tasksDetail.headline', { supplierName }) }}
              </h4>
              <h4
                v-if="headlineHover && headlineEllipsis"
                class="d-inline-block text-nowrap px-1 mx-n1 bg-white shadow rounded position-absolute text-typography-primary"
                style="z-index: 1"
              >
                {{ $t('tasksDetail.headline', { supplierName }) }}
              </h4>
            </div>
            <div
              role="button"
              @click.stop="closePopover"
              @mouseover="closeIconHover = true"
              @mouseleave="closeIconHover = false"
            >
              <CloseIcon :color="closeIconColor" width="20" height="20" />
            </div>
          </div>
        </div>
        <div
          v-for="task in tasks"
          :key="task.id"
          class="task-container container"
          @click.stop="handleTaskClick(task.id, task.type)"
        >
          <div class="top-element">
            <div class="task-type">
              {{ $t(`tasksDetail.${task.type}`) }}
            </div>
            <TaskTag :task="task" @updateTask="$emit('update-task', $event)">
              <template #task-snooze-modal-subtitle>
                <RestaurantSupplierSubtitle :supplier-name="supplierName" :customer-name="customerName" />
              </template>
            </TaskTag>
          </div>
          <div class="comment">
            {{ getDisplayCommentText(task) }}
          </div>
        </div>
      </div>
      <template #reference>
        <div @click.stop="togglePopover"><slot name="button" /></div>
      </template>
    </el-popover>
  </div>
</template>

<script>
import { ref } from 'vue';

import { CloseIcon } from '@/assets/icons';
import { captureScroll } from '@/directives/capture-scroll';

import { RestaurantSupplierSubtitle } from './';
import { getRouteName } from '../task';

export default {
  components: {
    TaskTag: () => import('@/modules/tasks/taskTag/TaskTag'),
    CloseIcon,
    RestaurantSupplierSubtitle,
  },
  directives: {
    captureScroll,
  },
  props: {
    tasks: { type: Array, default: () => [] },
    supplierName: { type: String, default: () => '' },
    customerName: { type: String, default: () => '' },
  },
  setup() {
    return {
      visible: ref(false),
      closeIconHover: ref(false),
      headlineHover: ref(false),
      headlineEllipsis: ref(false),
    };
  },

  computed: {
    closeIconColor() {
      return this.closeIconHover ? '#000000' : '#9295A5';
    },
  },
  updated() {
    this.headlineEllipsis = this.$refs.headlineText.offsetWidth < this.$refs.headlineText.scrollWidth;
  },
  methods: {
    togglePopover() {
      this.visible = !this.visible;
    },
    closePopover() {
      this.visible = false;
      this.$emit('close');
    },
    handleTaskClick(taskId, taskType) {
      const routeData = this.$router.resolve({ name: getRouteName(taskType), params: { taskId } });
      window.open(routeData.href, '_blank');
    },
    getDisplayCommentText(task) {
      return task.data?.comment ?? '-';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';
.main-container {
  margin: -12px;
  width: 300px;
}

.headline-text {
  flex-grow: 1;
  max-width: 85%;
}

.task-type {
  color: $typography-primary;
}

.container {
  padding: 16px;
  display: flex;
  flex-direction: column;
  &:not(:last-child) {
    border-bottom: 1px solid #d9dcde;
  }
}

.task-container {
  &:hover {
    cursor: pointer;
    background-color: #fbfbfb;
  }
}

.comment {
  color: $typography-secondary;
  margin-top: 8px;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: keep-all;
  &:hover {
    display: -webkit-box;
    -webkit-line-clamp: 20;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: keep-all;
  }
}

.top-element {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
