<template>
  <div class="task-card" @click="$emit('click')">
    <div class="card-column supplier">
      <div class="avatar">
        <SupplierIcon />
      </div>
      <div class="text-ellipsis">{{ supplier.name }}</div>
    </div>
    <div class="card-column" :class="info ? 'sub-line' : ''">
      <div>{{ action }}</div>
      <div class="text-muted">{{ info }}</div>
    </div>
    <div class="card-column">
      <div :class="{ completed: completed }">
        <div v-if="completed" class="approved-icon"><ApprovedIcon /></div>
        <div>{{ description }}</div>
      </div>
      <div v-if="completed" class="text-muted">{{ descriptionSubtitle }}</div>
    </div>
    <div class="card-column last text-muted">{{ completed ? formatDate(created) : formatTimeAgo(created) }}</div>
  </div>
</template>

<script>
import { DateTime } from 'luxon';

import { options } from '@/locale/dateConfig';
import { SupplierIcon, ApprovedIcon } from '@/assets/icons';

export default {
  components: { SupplierIcon, ApprovedIcon },
  props: {
    supplier: { type: Object, required: true },
    action: { type: String, required: true },
    info: { type: String, default: '' },
    description: { type: String, required: true },
    descriptionSubtitle: { type: String, default: '' },
    created: { type: Number, required: true },
    completed: { type: Number, default: null },
    user: { type: Object, default: null },
  },
  methods: {
    formatTimeAgo(ms) {
      return new DateTime.fromMillis(ms).toRelative();
    },
    formatDate(ms) {
      return ms ? new Date(ms).toLocaleDateString(this.$i18n.locale, options.short) : null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../style';

.supplier {
  display: grid;
  grid-template-columns: 36px 1fr;
  gap: 10px;
  align-items: center;

  .avatar {
    background: $secondary;
    border-radius: 36px;
    color: #9295a5;
    height: 36px;
    width: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.sub-line {
  align-self: flex-end;
  margin-bottom: 8px;
}

.completed {
  display: grid;
  grid-template-columns: 24px 1fr;
  gap: 10px;
  align-items: center;

  .approved-icon {
    height: 24px;
    width: 24px;
    color: $success;
  }
}
</style>
